var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getUser.role.slug === 'super-administrateur' ||
    _vm.getUser.role.slug === 'responsable-relation-client' ||
    _vm.getUser.role.slug === 'charge-daffaires' ||
    _vm.getUser.role.slug === 'rh'||
    _vm.getUser.role.slug === 'charge-de-clientele'||
    _vm.getUser.role.slug === 'assistant-rh'
  )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v("CHIFFRES")])],1),_c('b-row',{staticClass:"justify-content-start mx-1 mb-2 mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('h5',[_vm._v("Filtrage")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Sélectionner une intervalle de date","config":_vm.config},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"sm":"3"}},[_c('b-button',{attrs:{"disabled":_vm.isDateRangeStatisticsLoading || _vm.rangeDate == null,"variant":"primary"},on:{"click":function($event){return _vm.eraseNumbersStatistics()}}},[_vm._v(" "+_vm._s(_vm.isDateRangeStatisticsLoading ? "Chargement..." : "Effacer")+" ")])],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-2",attrs:{"cols":"12","md":"3"}},[_c('b-button',{ref:"reset",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.resetNumbersStatistics()}}},[_vm._v(" "+_vm._s(_vm.resetStatisticsLoading ? "Chargement..." : "Actualiser")+" ")])],1)],1),(_vm.isNumbersStatisticsLoading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('b-row',{staticClass:"mb-2"},_vm._l((Object.entries(_vm.getNumbersStatistics)),function(item,index){return _c('b-col',{key:index,staticStyle:{"height":"max-content !important"},attrs:{"lg":"4","sm":"6"}},[_c('statistic-card-horizontal',{attrs:{"isTaux":false,"icon":item[0]==='Nombre d\'employé inscrit' ? 'UsersIcon':
                        item[0]==='Nombre d\'employé occupé' ? 'UserIcon':
                        item[0]==='Nombre d\'employé confirmé' ? 'UserCheckIcon':
                        item[0]==='Nombre d\'employé qui ont désisté'? 'UserMinusIcon' :
                        item[0]==='Nombre d\'employé qui ont approuvé leurs contrat non déployé' ? 'UserIcon':
                        item[0]==='Nombre de contrat actif' ? 'FilePlusIcon':
                        item[0]==='Nombre de contrat clôturé' ? 'FileMinusIcon':
                        item[0]==='Nombre d\'employé désisté' ? 'UserMinusIcon':
                        item[0]==='Nombre de prospect' ? 'UserPlusIcon':
                        item[0]==='Nombre de client' ? 'UsersIcon':
                        item[0]==='Nombre de package affecté' ? 'FolderIcon':
                        item[0]==='Nombre de commandes récurrentes' ? 'FileIcon':
                        item[0]==='Nombre de commandes Business récurrentes' ? 'FileIcon':
                        item[0]==='Nombre d\'employé qui ont désisté pour les commandes Business récurrentes(dont 2 pour raison d\'incompétences et 6 pour résiliation normale' ? 'FileMinusIcon':
                        item[0]==='Nombre commandes Business récurrentes en attente d\'affectation' ? 'FileIcon':
                        item[0]==='Nombre commandes récurrentes en attente de propositions non soummises' ? 'FileIcon':
                        item[0]==='Nombre de commandes ponctuelles' ? 'FileIcon':
                        item[0]==='Nombre de commandes ponctuelles terminées' ? 'FilePlusIcon':
                        item[0]==='Nombre de commandes ponctuelles satisfaites' ? 'FilePlusIcon':
                        item[0]==='Nombre de commandes ponctuelles insatisfaites' ? 'FileMinusIcon':
                        item[0]==='Nombre de commandes ponctuelles non terminées' ? 'FileMinusIcon':
                        item[0]==='Nombre de commandes pontuelles non terminées' ? 'FileMinusIcon':
                        item[0]==='Nombre commandes récurrentes en attente de recommandation' ? 'FileIcon':
                        item[0]==='Nombre commandes récurrentes en attente de propositions' ? 'FileIcon':
                        item[0]==='Nombre de package apporté' ? 'FolderPlusIcon':
                        '',"color":"info","statistic":item[0]==='Nombre de package affecté'? item[1].numbers_affected_package :item[0]==='Nombre de package apporté'? item[1].numbers_brought_package :item[1].toLocaleString(),"statistic-title":item[0]}})],1)}),1)],1)],1)],1):_c('div',[_c('not-authorized')],1)}
var staticRenderFns = []

export { render, staticRenderFns }