<template>
  <b-row
    v-if="getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'responsable-relation-client' ||
      getUser.role.slug === 'charge-daffaires' ||
      getUser.role.slug === 'rh'||
      getUser.role.slug === 'charge-de-clientele'||
      getUser.role.slug === 'assistant-rh'
    "
  >
    <b-col cols="12">
      <b-card>
        <b-card-header>
          <b-card-title>CHIFFRES</b-card-title>
        </b-card-header>
        <b-row class="justify-content-start mx-1 mb-2 mt-2">
          <b-col
            md="6"
          >
            <b-form-group>
              <h5>Filtrage</h5>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Sélectionner une intervalle de date"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="3"
            class="mt-2"
          >
            <b-button
              :disabled="isDateRangeStatisticsLoading || rangeDate == null"
              variant="primary"
              @click="eraseNumbersStatistics()"
            >
              {{ isDateRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
            </b-button>
          </b-col>
          <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-end mb-2"
            >
              <b-button
                ref="reset"
                variant="primary"
                @click="resetNumbersStatistics()"
               
              >
                {{ resetStatisticsLoading ? "Chargement..." : "Actualiser" }}
              </b-button>
            </b-col>
        </b-row>
        <div
          v-if="isNumbersStatisticsLoading"
          class="d-flex justify-content-center"
        >
          <b-spinner
            variant="info"
            style="width: 3rem; height: 3rem;"
          />
        </div>

        <b-row
          v-else
          class="mb-2"
        >
          <b-col
            v-for="(item , index) in Object.entries(getNumbersStatistics)"
            :key="index"
            lg="4"
            sm="6"
            style="height:max-content !important;"
          >

          <statistic-card-horizontal
                        :isTaux = false
                        :icon="
                          item[0]==='Nombre d\'employé inscrit' ? 'UsersIcon':
                          item[0]==='Nombre d\'employé occupé' ? 'UserIcon':
                          item[0]==='Nombre d\'employé confirmé' ? 'UserCheckIcon':
                          item[0]==='Nombre d\'employé qui ont désisté'? 'UserMinusIcon' :
                          item[0]==='Nombre d\'employé qui ont approuvé leurs contrat non déployé' ? 'UserIcon':
                          item[0]==='Nombre de contrat actif' ? 'FilePlusIcon':
                          item[0]==='Nombre de contrat clôturé' ? 'FileMinusIcon':
                          item[0]==='Nombre d\'employé désisté' ? 'UserMinusIcon':
                          item[0]==='Nombre de prospect' ? 'UserPlusIcon':
                          item[0]==='Nombre de client' ? 'UsersIcon':
                          item[0]==='Nombre de package affecté' ? 'FolderIcon':
                          item[0]==='Nombre de commandes récurrentes' ? 'FileIcon':
                          item[0]==='Nombre de commandes Business récurrentes' ? 'FileIcon':
                          item[0]==='Nombre d\'employé qui ont désisté pour les commandes Business récurrentes(dont 2 pour raison d\'incompétences et 6 pour résiliation normale' ? 'FileMinusIcon':
                          item[0]==='Nombre commandes Business récurrentes en attente d\'affectation' ? 'FileIcon':
                          item[0]==='Nombre commandes récurrentes en attente de propositions non soummises' ? 'FileIcon':
                          item[0]==='Nombre de commandes ponctuelles' ? 'FileIcon':
                          item[0]==='Nombre de commandes ponctuelles terminées' ? 'FilePlusIcon':
                          item[0]==='Nombre de commandes ponctuelles satisfaites' ? 'FilePlusIcon':
                          item[0]==='Nombre de commandes ponctuelles insatisfaites' ? 'FileMinusIcon':
                          item[0]==='Nombre de commandes ponctuelles non terminées' ? 'FileMinusIcon':
                          item[0]==='Nombre de commandes pontuelles non terminées' ? 'FileMinusIcon':
                          item[0]==='Nombre commandes récurrentes en attente de recommandation' ? 'FileIcon':
                          item[0]==='Nombre commandes récurrentes en attente de propositions' ? 'FileIcon':
                          item[0]==='Nombre de package apporté' ? 'FolderPlusIcon':
                          ''"
                        color="info"
                        :statistic="item[0]==='Nombre de package affecté'? item[1].numbers_affected_package :item[0]==='Nombre de package apporté'? item[1].numbers_brought_package :item[1].toLocaleString()"
                        :statistic-title="
                          item[0]"
                      />
          </b-col>

        </b-row>

      </b-card>
    </b-col>

  </b-row>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  // BCardText,
  BCardTitle,
  // BCardSubTitle,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  // BAlert,
  BTable,
  BMedia,
  BAvatar,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'

// import VueApexCharts from 'vue-apexcharts'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import apexChatData from './apexChartData'
import StatisticsCard from './StatisticsCard.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    // VueApexCharts,
    // BAlert,
    BCardHeader,
    // BCardText,
    BCardTitle,
    // BCardSubTitle,
    BFormInput,
    BFormGroup,
    BTable,
    BMedia,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BSpinner,
    flatPickr,
    StatisticCardHorizontal,
    vSelect,
    // ToastificationContent,
    StatisticsCard,
  },
  data() {
    return {
      apexChatData,
      resetStatisticsLoading: false,
      invoices: [],
      isDateRangeStatisticsLoading: false,
      isMonthRangeStatisticsLoading: false,
      rangeDate: null,
      isLoading: true,
      commissioonsPerPeriod: null,
      proAmountPerPeriod: null,
      turnoverPerPeriod: null,
      totalAmountOfWagesPaid: null,
      totalAmountOfUnpaidWages: null,
      totalAmountOfWagesPaidByCustomers: null,
      turnoverGrowthRate: null,
      oneTimeOrderRevenueGrowthRate: null,
      growthRateOfNumberOfRegisteredProfessionals: null,
      growthRateOfNumberOfValidatedProfessionals: null,
      growthRateOfNumberOfRegisteredEmployees: null,
      growthRateOfNumberOfValidatedEmployees: null,
      employeesDropoutRate: null,
      customerGrowthRate: null,
      growthRateOfNumberOfCustomersInvitedByCC: null,
      placementFees: null,
      monthSelected: '',
      yearSelected: '',
      config: { mode: 'range' },
      avatarText,
    }
  },
  computed: {
    ...mapGetters('services', [
      'getServices',
      'getMostRequestedServices',
      'getRecurringServices',
    ]),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('customers', ['getCustomers']),
    ...mapGetters('orders', ['getOrders', 'getDirectOrders']),

    ...mapGetters('professionals', [
      'getProfessionals',
      'getBestProfessionals',
      'getProfessionalsPerServicesAndAddress',
      'getEmployees',
    ]),
    ...mapGetters('statistics', [
      'getProAmount',
      'getProfessionalsAmountPerServices',
      'getCommissins',
      'getCustomerConversion',
      'getSatisfiedOrder',
      'getUnsatisfiedOrder',
      'getStatistics',
      'getNumbersStatistics',
    ]),
    ...mapGetters([
      'isCommissionLoading',
      'isProAmountLoading',
      'isServicesLoading',
      'isRecurringServicesLoading',
      'isCustomerCommisionLoading',
      'isProAmountPerServiceLoading',

      'isCustomersLoading',
      'isReferredCustomersLoading',
      'isOrdersLoading',
      'isDirectOrdersLoading',
      'isProfessionalsLoading',
      'isSatisfiedOrderLoading',
      'isUnsatisfiedOrderLoading',
      'isEmployeesLoading',
      'isStatisticsLoading',
      'isNumbersStatisticsLoading',
    ]),

    

  
    yearOptions() {
      const data = []
      for (let i = 2021; i <= Number((new Date()).getFullYear().toString()); i++) {
        data.push({
          label: i.toString(),
          value: i.toString(),
        })
      }
      return data
    },
  },
  watch: {
    rangeDate(val) {
      if (val != null && val.split('to').length === 2) {
        this.applyNumbersRangeStatisticsAction(val)
      }
    },

   
  },
  created() {
    
  },
  methods: {
    ...mapActions('statistics', [
      'getNumbersRangeStatisticsAction',
      'getNumbersStatisticsAction',
      'getRCRangeStatisticsAction',
      'getRCStatisticsAction',
      'getCAStatisticsAction',
      'getCARangeStatisticsAction',
      'getCCStatisticsAction',
      'getCCRangeStatisticsAction',
      'getRHStatisticsAction',
      'getARHStatisticsNumberAction',
      'getRHRangeStatisticsAction',
      'getARHRangeStatisticsAction',
    ]),

    resetNumbersStatistics() {
      this.resetStatisticsLoading = true
      this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', true)
      if(this.getUser.role.slug === 'super-administrateur'){
      this.getNumbersStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'responsable-relation-client'){
      this.getRCStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'charge-daffaires'){
      this.getCAStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'charge-de-clientele'){
      this.getCCStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'rh'){
      this.getRHStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'assistant-rh'){
      this.getARHStatisticsNumberAction()
        .then(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.resetStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
    },

   eraseNumbersStatistics() {
      this.isDateRangeStatisticsLoading = true
      this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', true)
      if(this.getUser.role.slug === 'super-administrateur'){
      this.getNumbersStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'responsable-relation-client'){
      this.getRCStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'charge-daffaires'){
      this.getCAStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'charge-de-clientele'){
      this.getCCStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'rh'){
        this.isDateRangeStatisticsLoading = true
      this.getRHStatisticsAction()
        .then(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }
      if(this.getUser.role.slug === 'assistant-rh'){
        this.isDateRangeStatisticsLoading = true
      this.getARHStatisticsNumberAction()
        .then(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = fals
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(() => {
          this.rangeDate = null
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
      }

    },

    applyNumbersRangeStatisticsAction(val) {
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      this.isDateRangeStatisticsLoading = true
      this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', true)
      if(this.getUser.role.slug === 'super-administrateur'){
      this.getNumbersRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
      }
      if(this.getUser.role.slug === 'responsable-relation-client'){
      this.getRCRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
      }
      if(this.getUser.role.slug === 'charge-daffaires'){
      this.getCARangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
      }

      if(this.getUser.role.slug === 'charge-de-clientele'){
      this.getCCRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
      }


      if(this.getUser.role.slug === 'rh'){
      this.getRHRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
      }
      if(this.getUser.role.slug === 'assistant-rh'){
      this.isDateRangeStatisticsLoading = true
      this.getARHRangeStatisticsAction(payload)
        .then(response => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
        })
        .catch(error => {
          this.isDateRangeStatisticsLoading = false
          this.$store.commit('SET_IS_NUMBERS_STATISTICS_LOADING', false)
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
      }

    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
